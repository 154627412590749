@import "custom";
@import "~bootstrap/scss/bootstrap";
@import "bootstrap_override";
@import "~@fortawesome/fontawesome-free/css/solid.min.css";

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'LPBambus';
  src: url('../fonts/LPBambus-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'LPBambus';
  src: url('../fonts/LPBambus-Light.otf') format('truetype');
  font-weight: 300;
}

html,
body,
#root {
  height: 100%;
}

body {
  min-height: 1080px;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
  text-transform: uppercase;
}

label {
  font-weight: $font-weight-bold;

  p {
    margin-bottom: 0;

    & + p {
      margin-bottom: $spacer;
    }
  }
}

.link {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  cursor: pointer;

  @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: $white;

  .row {
    align-items: center;

    > * {
      display: flex;
      flex: 1;
    }
  }

  > .container {
    > * {
      flex: 1;
    }
  }
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 150px;
  background-color: $berger-blue;
  color: $white;
  margin-top: auto;

  ul {
    margin-bottom: 0;
  }

  .features {
    padding: 3rem 1rem;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .features::before {
    font-family: "Font Awesome 5 Free";
    content: '\f00c';
    margin-right: 1rem;
  }
}

.footer-scanner {
  position: relative;
  min-height: 100px;
}

.pfeil-scanner {
  width: 80px;
  height: 80px;
  background-color: var(--white);
  transform: translate(-50%, -50%) rotate(45deg);
  position: absolute;
  top: 0;
  left: 50%;
}

.scanner-hinweis {
  padding: 5rem 0 2rem;
  text-transform: uppercase;
  font-size: 1.5rem;
}

#root {
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5;
  font-size: $font-size-sm;
  overflow: hidden;
  position: relative;
}

#content {
  height: 100%;
  position: relative;

  &.with-footer {
    .page {
      bottom: 150px;
    }
  }
}

#link-to-info {
  display: flex;
  justify-content: flex-end;
}

#logo {
  display: flex;
  justify-content: center;
}

#background-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page {
  display: flex;
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  bottom: 0;
  top: 116px;
  background-color: var(--white);
  transition: all .5s ease;

  .container,
  .container-fluid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: relative;
  }
}

.blockquote {
  font-family: $font-family-secondary;
  font-size: 3rem;
  color: $berger-blue;
  transform: skew(0deg,-6deg);
}

.underline {
  border-bottom: 1px solid $gray-300;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
  font-size: .6875rem;
  text-align: center;
  cursor: pointer;

  .icon-wrapper-circle {
    background-color: $gray-300;
    color: $white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 2rem;
    }
  }

  .fa-info-circle:before {
    content: "i";
  }
}

.kachel {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: $white;
  padding: 3rem;
  font-size: 2.5rem;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1.2;

  &:hover {
    text-decoration: none;
    color: $white;
  }

  &.vorteilskarte {
    background-color: $berger-red;
  }

  &.produktscan {
    background-color: $berger-blue;
  }

  svg {
    font-size: 5rem;
    display: block;
    margin-bottom: 1.5rem;
  }
}

.vorteil {
  position: relative;

  &::before {
    content: '\f00c';
    position: absolute;
    left: -2rem;
    font-family: 'Font Awesome 5 Free';
    font-size: 1.25rem;
    color: $berger-blue;
  }
}


.box {
  position: relative;
  padding: 1rem 5rem;
  flex: 1;

  &-gray {
    background-color: $gray-300;

    &::before {
      background-color: $gray-300;
    }
  }
}

.arrow {
  &::before {
    position: absolute;
    content: '';
    width: 3rem;
    height: 3rem;
    transform: translate(-50%,-50%) rotate(45deg);
    transform-origin: center center;
    top: 50%;
  }

  &-left::before {
    left: 0;
  }

  &-right::before {
    right: 0;
    transform: translate(50%,-50%) rotate(45deg);
  }

  &-top::before {
    top: 4rem;
  }

  &-up::before {
    top: 0;
    left: 5rem;
  }

  &-down::before {
    top: 100%;
    left: 25%;
  }
}

.scanbox,
.vorteilsbox {
  font-size: $font-size-base;
}

.next-step {
  display: flex;
  background-color: $berger-red;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
  color: $white;
  font-size: 1.5rem;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  border: 0;
  width: 100%;

  &:hover {
    color: $white;
  }
}

.steps {
  list-style: none;
  padding: 0;

  li {
    margin: 2rem 0;
    display: block;
    position: relative;
    counter-increment: inst;
    padding-left: 4rem;

    &::before {
      content: counter(inst);
      background-color: $berger-blue;
      color: $white;
      font-size: 1.5rem;
      font-weight: bold;
      border-radius: 50%;
      display: flex;
      position: absolute;
      width: 3rem;
      height: 3rem;
      left: 0;
      justify-content: center;
      align-items: center;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      border-left: 2px dashed var(--berger-blue);
      content: '';
      position: absolute;
      left: 0;
      top: calc(50% + 1.5rem);
      height: 100%;
      margin-left: 1.5rem;
    }

    &:last-child::after {
      content: none;
    }
  }
}

.modal.show {
  opacity: 1;
  display: flex;
}

.modal-inner {
  width: 90%;
  height: 90%;
  max-width: 1500px;
  background-color: $white;
  padding: 4rem;
  overflow-y: scroll;
}

div#close-modal {
  position: absolute;
  right: 5%;
  top: 5%;
  background-color: $white;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
}

.price,
.current-price {
  font-size: $h2-font-size;
  font-weight: $font-weight-bold;

  .price-block__short & {
    font-size: 1.125rem;
  }
}

.price {
  color: $berger-blue;
}

.old-price {
  color: $berger-blue;
  font-weight: $font-weight-bold;
  text-decoration: line-through;
}

.current-price {
  color: $berger-red;
}

.rating {
  svg {
    font-size: 1.75rem;
  }

  &.rating-short {
    svg {
      font-size: 1rem;
    }
  }
}

.ratingstars * {
  fill: url(#rg);
}

.list-striped {
  list-style: none;
  padding: 0;

  > * {
    padding: .5rem;
  }

  > *:nth-child(2n) {
    background-color: $light;
  }
}

.xsell {
  a{
    &:hover {
      text-decoration: none;
    }
  }
}

//.tab-content {
//  overflow: scroll;
//}

.overflowy {
  &-scroll {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  &-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3rem;
    background-image: linear-gradient(transparent, $white);
  }
}

.carousel {
  .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
    background: $white;
    display: flex;
    justify-content: center;

    img {
      width: auto;
      max-width: 100%;
      max-height: 450px;
      vertical-align: top;
      border: 0;
    }
  }

  .thumbs-wrapper {
    margin: 1rem 2rem;
    overflow: hidden;
  }

  .thumbs {
    -webkit-transition: all 0.15s ease-in;
    -moz-transition: all 0.15s ease-in;
    -o-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap;
    padding: 0;
    margin-bottom: 0;
  }

  .thumb {
    -webkit-transition: border 0.15s ease-in;
    -moz-transition: border 0.15s ease-in;
    -o-transition: border 0.15s ease-in;
    transition: border 0.15s ease-in;
    display: inline-block;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px;
    vertical-align: middle;

    &.selected,
    &:hover {
      border: 3px solid $primary;
    }

    &:focus {
      border: 3px solid $primary;
      outline: none;
    }

    img {
      max-height: 100px;
      max-width: 100px;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .control-arrow {
    outline: 0;
    border: 0;
    padding: 0 2rem;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    font-size: 18px;
    filter: alpha(opacity=40);
    -webkit-transition: all 0.25s ease-in;
    -moz-transition: all 0.25s ease-in;
    -o-transition: all 0.25s ease-in;
    transition: all 0.25s ease-in;

    &.control-prev {
      left: 0;
      background: linear-gradient(-90deg, transparent, $white 1rem);

      &:before {
        border-right: 8px solid $primary;
      }
    }

    &.control-next {
      right: 0;
      background: linear-gradient(90deg, transparent, $white 1rem);

      &:before {
        border-left: 8px solid $primary;
      }
    }
  }
}

.carousel .control-arrow:hover {
  opacity: 1;
  filter: alpha(opacity=100); }
.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  content: ''; }
.carousel .control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: inherit;
  display: none; }
.carousel .control-prev.control-arrow {
  left: 0; }
.carousel .control-prev.control-arrow:before {
  border-right: 8px solid $primary; }
.carousel .control-next.control-arrow {
  right: 0; }
.carousel .control-next.control-arrow:before {
  border-left: 8px solid $primary; }
.carousel-root {
  outline: none; }
.carousel {
  position: relative;
  width: 100%; }
.carousel * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
.carousel img {
  width: 100%;
  display: inline-block;
  pointer-events: none; }
.carousel .carousel {
  position: relative; }
.carousel.carousel-slider {
  position: relative;
  margin: 0;
  overflow: hidden; }
.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px; }
.carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0.2); }
.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  width: 100%;
  -webkit-transition: height 0.15s ease-in;
  -moz-transition: height 0.15s ease-in;
  -o-transition: height 0.15s ease-in;
  transition: height 0.15s ease-in; }
.carousel .slider-wrapper.axis-horizontal .slider {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex; }
.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-direction: column;
  flex-flow: column; }
.carousel .slider-wrapper.axis-vertical {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex; }
.carousel .slider-wrapper.axis-vertical .slider {
  -webkit-flex-direction: column;
  flex-direction: column; }
.carousel .slider {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
  width: 100%; }
.carousel .slider.animated {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out; }
.carousel .slide iframe {
  display: inline-block;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  border: 0; }
.carousel .slide .legend {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  opacity: 0.25; }
.carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%; }
@media (min-width: 960px) {
  .carousel .control-dots {
    bottom: 0; } }
.carousel .control-dots .dot {
  -webkit-transition: opacity 0.25s ease-in;
  -moz-transition: opacity 0.25s ease-in;
  -o-transition: opacity 0.25s ease-in;
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px; }
.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100); }
.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
  color: #fff; }
.carousel:hover .slide .legend {
  opacity: 1; }
