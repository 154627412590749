.card {
  position: static;
  justify-content: center;
  align-items: center;
  flex: 0 1 600px;
  padding: 25px;
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.75);
  z-index: 1;
  opacity: 0;
  display: none;
  justify-content: center;
  align-items: center;
}

.nav-tabs {
  .nav-link {
    font-size: $h5-font-size;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: $body-color;

    &.active {
      color: $primary;
    }
  }
}

.toast {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, 0);
}