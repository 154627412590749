$berger-blue: #004481;
$berger-red: #E5003F;
$berger-lightgreen: #DBE7C1;
$berger-green: #619D2C;

$primary: $berger-blue;
$light: #dee2e6;

$theme-colors: (
  "berger-blue": $berger-blue,
  "berger-red": $berger-red,
  "berger-lightgreen": $berger-lightgreen,
  "berger-green": $berger-green,
);

$font-family-sans-serif: "OpenSans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-secondary: "LPBambus", sans-serif;
$font-weight-bold: 700;

$container-max-widths: (
  xl: 1700px
);

$headings-font-weight: $font-weight-bold;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$card-bg: transparent;
$card-border-width: 0;

$label-margin-bottom: 0.125rem;
